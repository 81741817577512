import * as Sentry from "@sentry/react";

import { environment } from "@config/environment.config";

export const initSentry = () => {
	if (environment.ENABLE_SENTRY) {
		Sentry.init({
			dsn: environment.SENTRY_DSN,
			environment: environment.ENV,
			attachStacktrace: true,
			tracesSampleRate: 1.0
		});
	}
};

export const sentryErrorAxiosInterceptor = (error) => {
	const isUnauthorized = error.response?.status === 401;
	const isCanceled = error?.message === "canceled";

	if (!isUnauthorized && !isCanceled) {
		const customMessage = error.response?.data?.message?.split("\n")[0];
		const customError = new Error(customMessage || error.message);
		customError.name = `(${error.response?.status || "Unknown Status"}) [${
			error.config?.method?.toUpperCase() || "UNKNOWN Method"
		}] ${error.config?.url || "Unknown URL"}`;
		customError.stack = error.stack;
		customError.response = error.response;
		customError.config = error.config;

		Sentry.captureException(customError, {
			...(customMessage && { fingerprint: customMessage }),
			extra: {
				...(error.config?.url && { url: error.config.url }),
				...(error.config?.method && { method: error.config.method }),
				...(error.config?.data && { data: error.config.data }),
				...(error.config?.params && { params: error.config.params }),
				...(error.response?.status && { status: error.response.status }),
				...(error.response?.statusText && { statusText: error.response.statusText }),
				...(error.response?.data && { responseData: error.response.data })
			}
		});
	}

	return Promise.reject(error);
};
