import axios from "axios";

import { environment } from "@config/environment.config";
import { sentryErrorAxiosInterceptor } from "@utils/sentry";

//ADD to .env REACT_APP_API_HOST_TEMPORAL_FINANCE=https://services-pre-temp.flexicar.es
const instance = axios.create({
  baseURL: environment.API_FINANCE,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 25000,
});

instance.interceptors.response.use((response) => response, sentryErrorAxiosInterceptor);

export default instance;
