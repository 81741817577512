import axios from "axios";

import { environment } from "@config/environment.config";
import { sentryErrorAxiosInterceptor } from "@utils/sentry";

const instance = axios.create({
  baseURL: environment.CRM_SERVICES,
  maxContentLength: Infinity,
  maxBodyLength: Infinity,
  timeout: 30000,
});

instance.interceptors.response.use((response) => response, sentryErrorAxiosInterceptor);

export default instance;
