import { useEffect, useCallback, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { MdInfoOutline } from "react-icons/md";
import { UncontrolledTooltip } from "reactstrap";
import Decimal from "decimal.js";

import styles from "@styles/views/SellContract/sell-data.module.scss";

import PaneHeader from "@components/PaneHeader";
import FormInput from "@components/Form/Input/FormInput";
import FormSelect from "@components/Form/Select/FormSelect";
import FormFormattedNumberInput from "@components/Form/FormattedNumberInput/FormFormattedNumberInput";
import FormCheckBox from "@components/Form/Checkbox/FormCheckbox";
import FormRadioButtonGroup from "@components/Form/RadioButtonGroup/FormRadioButtonGroup";
import FormIBAN from "@components/Form/IBAN/FormIBAN";
import {
	insouranceOptions,
	termOptions,
	transportDeliveryTypeOptions,
	transportSellTypeOptions,
	yesOrNotSelect
} from "@constants/selectOptions";
import BankOptions from "@constants/bankOptions";
import {
	SELL_CONTRACT_DEFAULT_PRICES,
	SELL_CONTRACT_PRICE_TYPES
} from "@constants/sellContratDefaults";
import instance from "@services/base";
import { API } from "@services/urlConstants";
import { errorToast } from "@helpers/toastFunctions";
import { UserContext } from "@contexts/UserContext";
import RolType, { IS_SUPER_ADMIN } from "@constants/roles";

const SellData = ({
	gestoriasOptions,
	dealershipsOptions,
	provincesOptions,
	warrantyOptions,
	prices
}) => {
	const { userType, rol } = useContext(UserContext);
	const { watch, setValue } = useFormContext();

	const priceType = watch("sellInfo.priceType");

	const wantsFinancing = watch("sellInfo.financing.wants");

	const wantsTransport = watch("sellInfo.transport.wants");
	const deliveryType = watch("sellInfo.transport.delivery.type");
	const dealershipDelivery = watch("sellInfo.transport.delivery.dealership");
	const useSameAddress = watch("sellInfo.transport.delivery.useSameAddress");

	const warranty = watch("sellInfo.warranty");
	const wantsInsurance = watch("sellInfo.insurance.wants");

	const transferDiscountSelected = watch("agentDiscount.transferCost.selected");
	const transportDiscountSelected = watch("agentDiscount.transport.selected");
	const warrantyDiscountSelected = watch("agentDiscount.warranty.selected");

	const transferDiscountAmount = watch("agentDiscount.transferCost.amount");
	const transportDiscountAmount = watch("agentDiscount.transport.amount");
	const warrantyDiscountAmount = watch("agentDiscount.warranty.amount");

	const watchedClientProvince = watch("client.province");
	const watchedDeliveryProvince = watch("sellInfo.transport.delivery.provinceDealership");
	const watchedAlternativeProvince = watch(
		"sellInfo.transport.delivery.alternativeAddress.province"
	);

	const priceTypeOptions = prices.buyAndSell
		? [
				{
					label: "PVD",
					value: SELL_CONTRACT_PRICE_TYPES.PVD
				}
		  ]
		: [
				{
					label: "PVP Contado",
					value: SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO
				},
				{
					label: "PVP Financiado",
					value: SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO
				}
		  ];

	const getProvinceByLocalId = useCallback(
		async (id) => {
			try {
				const response = await instance.post(API.GET_PROVINCIA_LOCAL, {
					id_local: id
				});
				setValue("sellInfo.transport.delivery.provinceDealership", response.data[0]);
			} catch (err) {
				errorToast(err?.response?.data?.message || "Error al obtener la provincia del local");
			}
		},
		[setValue]
	);

	const setWarrantyAmount = useCallback(() => {
		if (!warranty) return;

		const amount = warranty.price;

		setValue("sellInfo.warrantyAmount", amount);
	}, [warranty, setValue]);

	const setDefaultAmountTransport = useCallback(() => {
		setValue("sellInfo.transport.delivery.amount", SELL_CONTRACT_DEFAULT_PRICES.TRANSPORT_AMOUNT);
	}, [setValue]);

	const isRolTerritorial = IS_SUPER_ADMIN(userType) || rol.includes(RolType.TERRITORIAL);
	const isBuyAndSell = prices.buyAndSell && rol.includes(RolType.PROFESSIONALS_MANAGER);

	const isFinancingDiscountDisabled = () => {
		const financingDiscountDefault = prices.priceCash - (prices.priceOffer || prices.priceFinanced);
		const hasFinancingDiscountEditRol =
			IS_SUPER_ADMIN(userType) ||
			rol.includes(RolType.TERRITORIAL) ||
			rol.includes(RolType.TRADING_MANAGER);

		if (financingDiscountDefault === 0) return false;
		if (financingDiscountDefault > 0 && hasFinancingDiscountEditRol) return false;
		return true;
	};

	useEffect(() => {
		dealershipDelivery?.value && getProvinceByLocalId(dealershipDelivery.value);
	}, [dealershipDelivery, getProvinceByLocalId]);

	useEffect(() => {
		const pvp = {
			[SELL_CONTRACT_PRICE_TYPES.PVP_CONTADO]: prices.priceCash,
			[SELL_CONTRACT_PRICE_TYPES.PVP_FINANCIADO]: prices.priceOffer || prices.priceFinanced,
			[SELL_CONTRACT_PRICE_TYPES.PVD]: prices.priceDealerSelling
		}[priceType];

		setValue("sellInfo.pvp", pvp);
	}, [setValue, priceType, prices]);

	useEffect(() => {
		setWarrantyAmount();
	}, [setWarrantyAmount]);

	useEffect(() => {
		if (wantsTransport?.value === yesOrNotSelect[0].value) {
			setDefaultAmountTransport();
		}
	}, [setDefaultAmountTransport, wantsTransport?.value]);

	useEffect(() => {
		const values = [];
		if (transferDiscountSelected) values.push(transferDiscountAmount);
		if (transportDiscountSelected) values.push(transportDiscountAmount);
		if (warrantyDiscountSelected) values.push(warrantyDiscountAmount);

		const sumReduce = values.reduce((acc, num) => acc.plus(new Decimal(num)), new Decimal(0));
		setValue("agentDiscount.total", sumReduce.toNumber());
	}, [
		setValue,
		transferDiscountSelected,
		transportDiscountSelected,
		warrantyDiscountSelected,
		transferDiscountAmount,
		transportDiscountAmount,
		warrantyDiscountAmount
	]);

	useEffect(() => {
		const PROVINCE_BALEARES = 29;
		const isBaleares = [
			watchedClientProvince?.value,
			watchedDeliveryProvince?.value,
			watchedAlternativeProvince?.value
		].includes(PROVINCE_BALEARES);

		if (wantsTransport?.value === yesOrNotSelect[0].value) {
			setValue(
				"sellInfo.transport.delivery.amount",
				isBaleares
					? SELL_CONTRACT_DEFAULT_PRICES.TRANSPORT_BALEARES_AMOUNT
					: SELL_CONTRACT_DEFAULT_PRICES.TRANSPORT_AMOUNT
			);
		}
	}, [
		setValue,
		wantsTransport?.value,
		watchedClientProvince,
		watchedDeliveryProvince,
		watchedAlternativeProvince
	]);

	return (
		<div>
			<PaneHeader title="Datos de la venta" />
			<div className={styles.container}>
				<div className={styles.priceOptions}>
					<FormRadioButtonGroup
						name="sellInfo.priceType"
						options={priceTypeOptions}
					/>
				</div>
				<div className={styles.module}>
					<FormFormattedNumberInput
						name="sellInfo.pvp"
						label="PVP Venta"
						disabled
					/>
					<FormFormattedNumberInput
						name="agentDiscount.total"
						label="Cortesía comercial"
						disabled
					/>
				</div>

				<PaneHeader
					title="Titularidad"
					color="secondary"
				/>

				<div className={styles.module}>
					<FormSelect
						name="sellInfo.gestoria"
						label="Gestoría"
						options={gestoriasOptions}
					/>
					<FormFormattedNumberInput
						name="sellInfo.ownershipChangeAmount"
						label="Importe Cambio de titularidad"
						disabled={!(isRolTerritorial || isBuyAndSell)}
					/>
					<FormCheckBox
						name="agentDiscount.transferCost.selected"
						label="Cortesía comercial para el cambio de titularidad"
					/>

					{transferDiscountSelected && (
						<FormFormattedNumberInput
							name="agentDiscount.transferCost.amount"
							label="Cortesía comercial para el cambio de titularidad"
						/>
					)}
				</div>

				<PaneHeader
					title="Financiación"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.financing.wants"
						label="Financia"
						options={yesOrNotSelect}
					/>

					{wantsFinancing?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.financing.discount"
								label="Descuento financiación"
								disabled={isFinancingDiscountDisabled()}
							/>
							<FormSelect
								name="sellInfo.financing.financialEntity"
								label="Entidad financiera"
								options={BankOptions}
							/>

							<FormFormattedNumberInput
								name="sellInfo.financing.TIN"
								label="TIN"
								suffix=" "
								placeholder=" "
							/>
							<FormSelect
								name="sellInfo.financing.term"
								label="Plazo"
								options={termOptions}
							/>
							<FormFormattedNumberInput
								name="sellInfo.financing.quota"
								label="Cuota"
								suffix=" €/mes"
								placeholder=" "
							/>
						</>
					)}
				</div>

				<PaneHeader
					title="Transporte"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.transport.sellType"
						label="Tipo de venta"
						options={transportSellTypeOptions}
					/>

					<FormSelect
						name="sellInfo.transport.wants"
						label="Transporte"
						options={yesOrNotSelect}
					/>

					{/* If transport.wants es Si */}
					{wantsTransport?.value === yesOrNotSelect[0].value && (
						<>
							<FormFormattedNumberInput
								name="sellInfo.transport.delivery.amount"
								label="Importe de entrega"
								disabled={!(isRolTerritorial || isBuyAndSell)}
							/>
						</>
					)}
				</div>

				{wantsTransport?.value === yesOrNotSelect[0].value && (
					<div className={styles.module}>
						<FormSelect
							name="sellInfo.transport.delivery.type"
							label="Forma de entrega"
							options={transportDeliveryTypeOptions}
						/>

						{/* If transport.delivery.type === Tienda */}
						{deliveryType?.value === transportDeliveryTypeOptions[0].value && (
							<FormSelect
								name="sellInfo.transport.delivery.dealership"
								label="Tienda de entrega"
								options={dealershipsOptions}
							/>
						)}
						{/* If transport.delivery.type === Tienda */}
						{deliveryType?.value === transportDeliveryTypeOptions[0].value && (
							<FormSelect
								name="sellInfo.transport.delivery.provinceDealership"
								label="Provincia Entrega"
								options={provincesOptions}
								isDisabled
							/>
						)}
						{/* If transport.delivery.type === Domicilio */}
						{deliveryType?.value === transportDeliveryTypeOptions[1].value && (
							<FormCheckBox
								name="sellInfo.transport.delivery.useSameAddress"
								label="Utilizar dirección de facturación"
							/>
						)}
					</div>
				)}

				{/* If transport.delivery.type === Domicilio && If transport.delivery.useSameAddress === false */}
				{deliveryType?.value === transportDeliveryTypeOptions[1].value && !useSameAddress && (
					<div className={styles.module}>
						<div className={styles.alternativeAddress}>
							<FormSelect
								name="sellInfo.transport.delivery.alternativeAddress.province"
								label="Provincia"
								options={provincesOptions}
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress.town"
								label="Población"
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress.address"
								label="Dirección"
							/>
							<FormInput
								name="sellInfo.transport.delivery.alternativeAddress.CP"
								label="CP"
							/>
						</div>
					</div>
				)}

				{wantsTransport?.value === yesOrNotSelect[0].value && (
					<div className={styles.module}>
						<FormCheckBox
							name="agentDiscount.transport.selected"
							label="Cortesía comercial para la entrega"
						/>
						{transportDiscountSelected && (
							<FormFormattedNumberInput
								name="agentDiscount.transport.amount"
								label="Cortesía comercial para la entrega"
							/>
						)}
					</div>
				)}

				{!prices.buyAndSell && (
					<>
						<PaneHeader
							title="Garantía"
							color="secondary"
						/>
						<div className={styles.module}>
							<FormSelect
								name="sellInfo.warranty"
								label={
									<div className={styles.extraWarrantyTooltip}>
										Tipo de Garantía
										<MdInfoOutline
											id="garantia-tooltip"
											className={styles.extraWarrantyTooltipIcon}
											size={22}
										/>
										<UncontrolledTooltip
											target="garantia-tooltip"
											style={{ opacity: 0.8 }}
											autohide={false}
										>
											La Garantía Extendida amplía la cobertura de la Garantía Premium durante un
											año adicional y facilita un vehículo de cortesía durante el primer año a
											través de Caser. No disponible para vehículos a partir de 8 años o 140.000km.
											Ni marcas fuera de la siguiente lista: ABARTH, AIWAYS, ALFA ROMEO, AUDI, BMW,
											CITROEN, CUPRA, DACIA, DFSK, DS, EVO, FIAT, FORD, HONDA, HYUNDAI, INFINITI,
											JEEP, KIA, LANCIA, LAND ROVER, LEXUS, LYNK & CO, MAHINDRA, MAZDA, MERCEDES,
											MG, MINI, MITSUBISHI, NISSAN, OMODA, OPEL, PEUGEOT, POLESTAR, RENAULT, SEAT,
											SKODA, SMART, SSANGYONG, SUBARU, SUZUKI, TESLA, TOYOTA, VOLKSWAGEN, VOLVO
										</UncontrolledTooltip>
									</div>
								}
								options={warrantyOptions}
							/>
						</div>
						<div className={styles.module}>
							{warranty?.price > 0 && (
								<FormFormattedNumberInput
									name="sellInfo.warrantyAmount"
									label="Importe"
									disabled={!isRolTerritorial}
								/>
							)}

							{warranty?.extra === 1 && (
								<FormIBAN
									name="sellInfo.warrantyIBAN"
									label="Nº de cuenta"
								/>
							)}

							{warranty?.basic === 0 && (
								<>
									<FormCheckBox
										name="agentDiscount.warranty.selected"
										label="Cortesía comercial para la garantía"
									/>
									{warrantyDiscountSelected && (
										<FormFormattedNumberInput
											name="agentDiscount.warranty.amount"
											label="Cortesía comercial para la garantía"
										/>
									)}
								</>
							)}
						</div>
					</>
				)}

				<PaneHeader
					title="Seguro del vehículo"
					color="secondary"
				/>
				<div className={styles.module}>
					<FormSelect
						name="sellInfo.insurance.wants"
						label="Seguro Auto"
						options={yesOrNotSelect}
					/>

					{/* If sellInfo.insurance.wants es Si */}
					{wantsInsurance?.value === yesOrNotSelect[0].value && (
						<>
							<FormSelect
								name="sellInfo.insurance.company"
								label="Compañía"
								options={insouranceOptions}
							/>
							<FormSelect
								name="sellInfo.insurance.financing"
								label="Financia"
								options={yesOrNotSelect}
							/>
							<FormFormattedNumberInput
								name="sellInfo.insurance.amount"
								label="Importe Seguro Auto"
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default SellData;
