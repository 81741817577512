export const SELL_CONTRACT_DEFAULT_PRICES = Object.freeze({
	OWNERSHIP_AMOUNT: 390,
	TIN: 0,
	QUOTA: 0,
	TRANSPORT_AMOUNT: 360,
	TRANSPORT_BALEARES_AMOUNT: 650,
	WARRANTY_PREMIUM_AMOUNT_MANUAL: 450,
	WARRANTY_PREMIUM_AMOUNT_AUTOMATIC:650,
	WARRANTY_EXTRA_AMOUNT_MANUAl: 1000,
	WARRANTY_EXTRA_AMOUNT_AUTOMATIC:1200,
	INSURANCE_AMOUNT: 0
});

export const SELL_CONTRACT_PRICE_TYPES = Object.freeze({
	PVP_CONTADO: 1,
	PVP_FINANCIADO: 2,
	PVD: 3
});
